// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-failed-js": () => import("../src/pages/contact-failed.js" /* webpackChunkName: "component---src-pages-contact-failed-js" */),
  "component---src-pages-contact-received-js": () => import("../src/pages/contact-received.js" /* webpackChunkName: "component---src-pages-contact-received-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

